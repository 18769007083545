body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.message-box-label {
  font-size: 1rem;
}

@media screen and (max-width: 655px) {
  .message-box-label {
    font-size: 0.8rem;
  }
}

.bg-secondary {
  background-color: #e7a03c;
}

.text-secondary {
  color: #e7a03c;
}

.w-fit {
  width: auto;
  max-width: 100%;
  display: inline-block;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 0 2rem;
}

@media screen and (min-width: 300px) {
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    max-width: "100%";
  }
}
@media screen and (min-width: 500px) {
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    width: 70vw;
    padding: 0;
  }
}

::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

::-webkit-scrollbar-thumb {
  background-color: #ffaa4a;
  border-radius: 0.4rem;
  border: none;
}
::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 0.4rem;
  border: none;
}

@media (min-width: 768px) {
  .overflow-md-hidden {
    overflow: hidden;
  }
}

#formModel::-webkit-scrollbar {
  display: none !important;
}

.tooltip-container {
  --background: red;
  position: relative;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  padding: 0.7em 1.8em;
  text-transform: uppercase;
  display: grid;
  place-items: center;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.3em 0.6em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  background: var(--background);
  z-index: -1;
  border-radius: 8px;
  scale: 0;
  transform-origin: 0 0;
  width: 100%;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  box-shadow: rgba(255, 0, 0, 0.25) 0 8px 15px;
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(--background);
}

.tooltip-container:hover .tooltip {
  top: -130%;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  scale: 1;
  animation: shake 0.5s ease-in-out both;
}

@keyframes shake {
  0% {
    rotate: 0;
  }

  25% {
    rotate: 7deg;
  }

  50% {
    rotate: -7deg;
  }

  75% {
    rotate: 1deg;
  }

  100% {
    rotate: 0;
  }
}

.single-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.md-h-100dvh {
  height: auto;
}

.paymentBtnPostion{
  position: fixed;
  bottom: 0;
  width: 100%;
  display: grid;
  place-items: center;
}

.filterFormMenu{
  /* position: absolute; */
  width: 100%;
  max-width: 100vw;
}

.fw-md-semibold{
  font-weight: normal;
}

.visitCountBlock{
  padding: 1rem;
}

@keyframes leftRightShake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shakeSavings {
  animation: leftRightShake 0.5s ease-in-out forwards;
}


@media only screen and (min-width: 768px) {
  .md-h-100dvh {
    height: 100dvh;
  }
  .paymentBtnPostion{
    position: static;
  }
  .visitAndOtherDetails{
    border-radius: 22px;
  }
  .filterFormMenu{
    /* position: static;
    transform: translateX(0); */
    width: 282px;
  }
  .fw-md-semibold{
    font-weight: bold;
  }
}

/* From Uiverse.io by Pradeepsaranbishnoi */ 
.radio-button {
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  align-items: center;
  color: white;
}

.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #E7A03C;
  transform: translateZ(-25px);
  transition: all 0.3s ease-in-out;
}

.radio::before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: #E7A03C;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.radio-button input[type="radio"]:checked + .radio {
  border-color: #E7A03C;
  transform: translateZ(0px);
  background-color: #E7A03C;
}

.radio-button input[type="radio"]:checked + .radio::before {
  opacity: 1;
}
