.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message-box {
  height: 150px;
}

@media screen and (max-width: 655px) {
  .message-box {
    height: 80px;
  }
}

@media (max-width: 1434px) {
  .d-none-1434 {
    display: none !important; /* Ensure it overrides Bootstrap's classes */
  }
}

.formModelArrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #ff0000;
  z-index: 100;
  transition: all 300ms;
}

@media only screen and (min-width: 900px) {
  .formModelArrows {
    position: static;
    background: #ff0000;
    z-index: 10;
    transition: all 300ms;
  }
}

.formModelArrows svg {
  stroke: white;
}

.option {
  transition: all 300ms;
}

.option:hover {
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
}

.aboutDetail {
  width: 100% !important;
}

@media only screen and (min-width: 576px) {
  .aboutDetail {
    width: 50% !important;
  }
}

.cartItemDetails .video {
  width: 100% !important;
}

@media (min-width: 768px) {
  .cartItemDetails .video {
    width: 50% !important;
  }
}
